<template>
	<v-layout class="login" align-center justify-center>
		<v-flex xs12 sm8 md4>
			<div class="text-center">
				<v-img src="../assets/logo-full.png" width="250" class="logo mx-auto my-4" />
			</div>
			<v-form @submit.prevent="login" ref="form">
				<v-card class="elevation-8 rounded-medium">
					<v-toolbar elevation="0" height="60">
						<v-spacer/>
						<v-toolbar-title>{{$t('Login.sign-in')}}</v-toolbar-title>
						<v-spacer/>
					</v-toolbar>

					<v-card-text class="pt-4 pb-0">
						<v-text-field
							prepend-icon="mdi-account"
							name="login"
							:label="$t('Login.username')"
							type="text"
							outlined
							dense
							class="rounded-medium"
							v-model="username"
							:disabled="loading"
							:rules="rules.required"
						></v-text-field>
						<v-text-field
							id="Password"
							prepend-icon="mdi-lock"
							name="password"
							:label="$t('Login.password')"
							type="password"
							outlined
							dense
							class="rounded-medium"
							v-model="password"
							:disabled="loading"
							:rules="rules.required"
						></v-text-field>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							type="submit"
							:disabled="loading"
							:loading="loading"
							class="rounded-medium"
						>
							{{$t('Login.sign-in')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
			<v-alert
				:value="error != null"
				v-if="!loading && error"
				type="error"
				class="rounded-medium mt-3"
				transition="scale-transition"
			>{{ error }}</v-alert>
			
			<div class="powered-by mt-5 px-2 pb-1">
				<p class="mb-0">Midad CMS - v{{version}}</p>
				<p class="mb-0">
					powered by
					<a target="_blank" href="https://www.beetronix.com">Beetronix</a>
				</p>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
import { dashboard } from '@/configs/routes.config';
import rules from '@/helpers/validation rules'
import { VERSION } from '@/helpers/constants';
export default {
	data: () => ({
		username: null,
		password: null,
		loading: false,

		error: null,
		
		version: VERSION,
		rules
	}),

	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$store.dispatch('auth/login', {
					username: this.username,
					password: this.password
				}).then(() => {
					this.$router.replace({ name: dashboard.name })
				}).catch((e) => {
					this.error = e.response.data.error;
				}).finally(() => {
					this.loading = false;
				})
			}
		}
	},
}
</script>

<style lang="scss">
.login{
	height: 90vh;
	
	.logo{
		filter: drop-shadow(0px 6px 5px #aaa);
		max-width: 45%;
		min-width: 150px;
	}

	.powered-by {
		position: absolute;
		bottom: 10px;
		left: 0;

		width: 100%;

		* {
			font-size: .82rem !important;
		}
	}
}
</style>