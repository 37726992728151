<template>
	<v-app-bar
		app
		color="white"
		height="55"
		elevation="2"
	>
		<!-- toggle drawer -->
		<v-btn
			text
			fab
			small
			@click="$emit('change-drawer')"
			class="me-2"
		>
            <v-icon>mdi-menu</v-icon>
        </v-btn>
		
		<!-- title -->
		<h1 class="text-md-h5 text-h6 primary--text font-weight-medium text-capitalize">
			{{getRouteTitle()}}
		</h1>
		<v-spacer/>

		<!-- notification & settings & lang -->
		<div class="d-flex align-center align-self-stretch">
			<template v-if="false &&$vuetify.breakpoint.smAndUp">
				<v-btn text icon>
					<v-icon>mdi-bell</v-icon>
				</v-btn>
				<v-btn text icon class="mx-1">
					<v-icon>mdi-cog</v-icon>
				</v-btn>

				<v-divider vertical inset class="mx-2"/>

				<!-- languages -->
				<template v-if="multiLangUI">
					<v-btn text icon class="mx-1">
						<v-menu offset-y>
							<template v-slot:activator="{ attr, on }">
								<v-btn icon v-bind="attr" v-on="on">
									<v-icon>mdi-translate</v-icon>
								</v-btn>
							</template>
					
							<v-list dense>
								<v-list-item
									v-for="lang in langs"
									:key="lang.key"
									@click="changeI18nLang(lang.key)"
								>
									<v-list-item-avatar size="23">
										<v-img :src="require(`../assets/flags/${lang.key}.png`)"/>
									</v-list-item-avatar>
									<v-list-item-title class="text-uppercase">
										{{`${lang.key} - ${lang.name}`}}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-btn>
					
					<v-divider vertical inset class="mx-2"/>
				</template>
			</template>

			<!-- user menu -->
			<v-menu offset-y :close-on-content-click="false">
				<!-- menu activator -->
				<template v-slot:activator="{ attrs, on }">
					<v-btn
						text
						:large="$vuetify.breakpoint.smAndUp"
						:small="$vuetify.breakpoint.smAndDown"
						class="px-3 text-capitalize"
						v-bind="attrs"
						v-on="on"
					>
						<template v-if="$vuetify.breakpoint.smAndUp">
							<v-avatar size="35">
								<v-img src="../assets/user.png"/>
							</v-avatar>
							<span class="mx-2">
								{{self.name ? self.name : 'Username'}}
							</span>
						</template>
						<v-icon>mdi-chevron-down</v-icon>
					</v-btn>
				</template>

				<!-- menu content -->
				<v-list nav dense>
					<template v-if="false && $vuetify.breakpoint.smAndDown">
						<v-list-item>
							<v-list-item-icon><v-icon>mdi-bell</v-icon></v-list-item-icon>
							<v-list-item-title>{{ $t('Navbar.menu.notification') }}</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
							<v-list-item-title>{{ $t('Navbar.menu.settings') }}</v-list-item-title>
						</v-list-item>
						
						<!-- language -->
						<v-list-group v-if="multiLangUI" active-class="primary--text">
							<template v-slot:activator>
								<v-list-item class="px-0">
									<v-list-item-icon><v-icon>mdi-translate</v-icon></v-list-item-icon>
									<v-list-item-title>{{ $t('Navbar.menu.language') }}</v-list-item-title>
								</v-list-item>
							</template>
							
							<v-list-item
								v-for="lang in langs"
								:key="lang.key"
								@click="changeI18nLang(lang.key)"
							>
								<v-list-item-avatar size="23">
									<v-img :src="require(`../assets/flags/${lang.key}.png`)"/>
								</v-list-item-avatar>
								<v-list-item-title class="text-uppercase">
									{{`${lang.key} - ${lang.name}`}}
								</v-list-item-title>
							</v-list-item>
						</v-list-group>
					</template>
					
					<!-- logout -->
					<v-list-item @click="logout">
						<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
						<v-list-item-title>{{ $t('Navbar.menu.logout') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
	</v-app-bar>
</template>

<script>
import routes from '@/router/routes';
import * as routesConfig from '@/configs/routes.config';
import { mapState } from 'vuex';
import { langs, multiLangUI } from '@/configs/translates.config';
import { changeI18nLang } from '@/i18n/i18n';
export default {
	name: 'Navbar',

	data: () => ({
		langs,
		multiLangUI
	}),

	computed: {
		...mapState({
			self: state => state.auth.self
		})
	},

	methods: {
		getRouteTitle() {
			const route = routes.find(c => c.name === this.$route.name);
			
			if (route.isAddEditPage) {
				const action = (this.$route.params.id ? this.$t('edit') : this.$t('add')) + ' ';
				return action + route.title[this.$i18n.locale].singular;
			}
			return route.title[this.$i18n.locale].plural;
		},

		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.replace({ name: routesConfig.login.name });
		},

		changeI18nLang
	}
};
</script>

<style>
</style>